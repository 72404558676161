import React, { useState, useEffect } from "react";
import "./landingPage.css";
import Navigation from "../Navbar/Navbar";
import backgroundImage from "../../Assests/villaLandingPage-min.jpg";
import { FaArrowUp } from "react-icons/fa6";

function LandingPage() {
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    const scrollY = document.documentElement.scrollTop;
    if (scrollY > 10) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  return (
    <section className="landing-page text-white">
      <img
        src={backgroundImage}
        alt="villa Background"
        className="background-img top-0 left-0 position-absolute"
        loading="lazy"
      />
      <Navigation className="fixed-top" />
      <div className="container-fluid">
        <div className="row d-flex flex-column align-items-center justify-content-center">
          <div className="col-md-6 text-container position-relative d-flex overflow-hidden justify-content-center align-items-center text-center w-100">
            <h1 className="buySellAnimation position-absolute overflow-hidden ">
              BUY & SELL
            </h1>
          </div>
          <div className="getStartedBtn text-center">
            {/* <a href="#buy">
              <button className="getStartedButton  border-0 fw-bold">
                Get Started
              </button>
            </a> */}
        <a href="#buy" class="btn41-43 btn-42">
            Get Started
        </a>
          </div>
        </div>
      </div>
      {sticky && (
        <a href="#home">
          <button className="position-fixed  bottom-0  bg-secondary uparrowButton border-0 text-white">
            <FaArrowUp />
          </button>
        </a>
      )}
    </section>
  );
}

export default LandingPage;

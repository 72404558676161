import React, { useState } from "react";
import "./loginForm.css";
import axios from "axios";

function LoginForm() {
  const [LoginData, setLoginData] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const inputField = " p-2 loginInput rounded-3";

  const handleChange = (e) => {
    const { name, value } = e.target;

    setLoginData({ ...LoginData, [name]: value });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      axios.post("url", LoginData).then((response) => {
        setLoginData({
          email: "",
          password: "",
        }).catch((err) => {
          console.log(err);
        });
      });
    } catch (err) {}
    // console.log(LoginData);
  };

  return (
    <section className="wr_loginForm d-flex flex-column justify-content-center align-items-center">

      <form
        onSubmit={handleSubmit}
        className="d-flex flex-column mx-auto align-itrems-center  loginForm "
      >
      <h2 className="text-center ">Login Form</h2>
        <fieldset className="d-flex flex-column mb-3">
          <label htmlFor="email" className="mb-2">
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            onChange={handleChange}
            className={inputField}
            value={LoginData.email}
            placeholder="Enter Your Email Address"
          />
        </fieldset>
        <fieldset className="d-flex flex-column mb-2">
          <label htmlFor="password" className="mb-2">
            Password
          </label>
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            id="password"
            onChange={handleChange}
            className={inputField}
            value={LoginData.password}
            placeholder="Password"
          />
        </fieldset>
        <fieldset class="mb-3">
          <div className="d-flex">
            <input
              type="checkbox"
              id="showPassword"
              onClick={handleTogglePassword}
            />
            <label htmlFor="showPassword" className="ps-2">
              Show Password
            </label>
          </div>
        </fieldset>

        <input type="submit" className="btn btn-primary" />
      </form>
    </section>
  );
}

export default LoginForm;

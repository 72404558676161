import React from "react";
import "./services.css";
import {
  FaHouseCircleCheck,
  FaPeopleRobbery,
  FaArrowsDownToPeople,
} from "react-icons/fa6";
import { VscGraph } from "react-icons/vsc";
import { FaCamera } from "react-icons/fa";
import { HiOutlineDocumentSearch } from "react-icons/hi";
import { GiMoneyStack } from "react-icons/gi";
import { BsBank2 } from "react-icons/bs";
import { MdOutlineSupportAgent } from "react-icons/md";

const serviceData = [
  { icon: <VscGraph />, name: "Market Analysis" },
  { icon: <FaCamera />, name: "Professional Photography" },
  { icon: <HiOutlineDocumentSearch />, name: "Legal Assistance" },
  { icon: <GiMoneyStack />, name: "Transparent Pricing" },
  { icon: <FaArrowsDownToPeople />, name: "Negotiation Expertise" },
  { icon: <BsBank2 />, name: "Financing Assistance" },
  { icon: <MdOutlineSupportAgent />, name: "After-Sales Support" },
  { icon: <FaHouseCircleCheck />, name: "Property Listings" },
  { icon: <FaPeopleRobbery />, name: "Buyer Representation" },

  { icon: <MdOutlineSupportAgent />, name: "After-Sales Support" },
  { icon: <FaHouseCircleCheck />, name: "Property Listings" },
  { icon: <FaPeopleRobbery />, name: "Buyer Representation" },
];

function Services() {
  return (
    <section className="villaServices d-flex flex-column justify-content-center align-items-center">
      <h2 className="mb-2 mb-md-5">Our Services</h2>

      <div className="services-container d-flex flex-wrap justify-content-center">
        {serviceData.map((service, index) => (
          <div
            key={index}
            className="service-item text-center align-items-center d-grid"
          >
            <div className="service-icon">{service.icon}</div>
            <div className="service-name mt-3">{service.name}</div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Services;

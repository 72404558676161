import React from "react";
import { FaInstagram, FaWhatsapp } from "react-icons/fa6";
import { AiOutlineYoutube } from "react-icons/ai";
import "./footer.css";

function Footer() {
  return (
    <>
      <section className="d-flex flex-column justify-content-center align-items-center villaFooterWrapper">
        <div className="d-flex justify-content-center p-3 py-0">
          <h2 className="p-2">
            <FaInstagram />
          </h2>
          <h2 className="p-2">
            <AiOutlineYoutube />
          </h2>
          <h2 className="p-2">
            <FaWhatsapp />
          </h2>
        </div>
        <p className="m-0 mb-1">&#169; 2024 onevilla All rights reserved.</p>
        <div className="m-0 d-flex justify-content-center">
          <p className="m-0 mb-1">
            <b>Developed By:</b>
            <a
              href="https://svsrco.com"
              target="_blank"
              rel="noreferrer"
              className="text-decoration-none"
            >
              &nbsp;&nbsp;SVSR Communications Pvt Ltd
            </a>
          </p>
        </div>
      </section>
    </>
  );
}

export default Footer;

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "./propertyPage.css";
import { FaLocationDot } from "react-icons/fa6";
import { FaRupeeSign } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { CiPhone } from "react-icons/ci";
import { IoLocationOutline } from "react-icons/io5";
import { BsFillBuildingsFill, BsBoxes } from "react-icons/bs";
import { FaCompass } from "react-icons/fa";
import { TbSofaOff } from "react-icons/tb";
import { PiHouseLight } from "react-icons/pi";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import {
  MdOutlineNumbers,
  MdOutlineEmail,
  MdOutlineHorizontalRule,
  MdOutlineKeyboardArrowLeft,
} from "react-icons/md";
import { LuSofa } from "react-icons/lu";
import config from "../../boot/config.json";

function PropertyPage() {
  const navigate = useNavigate();

  const [clickStatus, setClickStatus] = useState(false);
  const [contactUsStatus, setContactUsStatus] = useState(true);
  const [propertyDetails, setPropertyDetails] = useState([]);

  function handleAllDetailsStatus() {
    setClickStatus(!clickStatus);
  }
  // function handleContactUsStatus() {
  //   setContactUsStatus(!contactUsStatus);
  // }
  function contactUsPage(e, navi) {
    if (navi !== "back") {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 300);
      navigate("/");
    } else {
      navigate("/");
    }
  }

  const { id } = useParams();

  const ID = id.substring(id.length - 24);

  useEffect(() => {
    (() => {
      try {
        axios
          .get(`${config.baseUrl}/properties/getEachProperty/${ID}`)
          .then((res) => {
            console.log(res.data.data);
            setPropertyDetails(res.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    })();
  }, [ID]);
  return (
    <div className="mt-md-5">
      <div
        className="bg-dark  villaBackBtn d-grid justify-content-center align-items-center position-absolute  fw-bolder fs-4 text-white   m-2 "
        onClick={(e) => contactUsPage(e, "back")}
      >
        <MdOutlineKeyboardArrowLeft className="backButtonIcon" />
      </div>
      <div className="d-flex justify-content-center ">
        <img
          src={propertyDetails.imageUrl}
          alt={propertyDetails.area}
          className="propertyImage w-100"
        />
      </div>

      <div className="w-100 wr_detailsBlock">
        <div className="d-grid detailsBlock mx-auto rounded-3 my-3">
          <div className="detailsSubBlock pt-2 d-flex flex-column">
            <h5> Address </h5>
            <p className="d-flex  align-items-start">
              <div>
                <FaLocationDot className="me-2" />
                {propertyDetails.propertyAddress},{propertyDetails.city},
                {propertyDetails.state}
              </div>
            </p>
          </div>
          <div className="detailsSubBlock pt-2 d-flex flex-column">
            <h5> Price Per Sqft</h5>
            <p className="d-flex align-items-start">
              <div>
                <FaRupeeSign className="me-2" />
                {Number(propertyDetails.pricePerSquareFeet).toLocaleString(
                  "en-IN"
                )}
                /-
              </div>
            </p>
          </div>
          <div className="detailsSubBlock pt-2 d-flex flex-column">
            <h5>Total Number Sqft</h5>
            <p className="d-flex align-items-start">
              <div>
                <BsFillBuildingsFill className="me-2" />
                {propertyDetails.squareFeet} sqft
              </div>
            </p>
          </div>
          <div className="detailsSubBlock pt-2 d-flex flex-column">
            <h5>Facing </h5>
            <p className="d-flex align-items-start">
              <div>
                <FaCompass className="me-2" /> {propertyDetails.facing}
              </div>
            </p>
          </div>
          <div className="detailsSubBlock pt-2 d-flex flex-column">
            <h5>Type Of Property</h5>
            <p className="d-flex align-items-start">
              <div>
                <PiHouseLight className="me-2" />
                {propertyDetails.typeOfProperty}
              </div>
            </p>
          </div>
          <div className="detailsSubBlock pt-2 d-flex flex-column">
            <h5> RERA Number </h5>
            <p className="d-flex align-items-start">
              <div>
                <MdOutlineNumbers className="me-2" /> {propertyDetails.reraNo}
              </div>
            </p>
          </div>
          <div className="detailsSubBlock pt-2 d-flex flex-column">
            <h5>Furnished Status </h5>
            <p className="d-flex align-items-start">
              <div>
                {propertyDetails.furnishedStatus === "Semi-Furnished" ||
                propertyDetails.furnishedStatus === "Furnished" ? (
                  <LuSofa className="me-2" />
                ) : (
                  <TbSofaOff className="me-2" />
                )}
                {propertyDetails.furnishedStatus}
              </div>
            </p>
          </div>
          <div className="detailsSubBlock pt-2 d-flex flex-column">
            <h5>Number of Rooms </h5>
            <p className="d-flex align-items-start">
              <div>
                <BsBoxes className="me-2" />
                {propertyDetails.roomsCount} Rooms
              </div>
            </p>
          </div>
          <div className="detailsSubBlock pt-2 d-flex flex-column">
            <h5>Total Price </h5>
            <p className="d-flex align-items-start">
              <div>
                <FaRupeeSign className="me-2" />
                {Number(propertyDetails.price).toLocaleString("en-IN")} /-
              </div>
            </p>
          </div>
          {/* <div className="detailsSubBlock pt-2 d-flex flex-column">
            <p className="d-flex align-items-start ">
              <div className="viewImagesBtn fw-bold">
                View More Images <MdOutlineKeyboardDoubleArrowRight />
              </div>
            </p>
          </div> */}
        </div>
      </div>

      <div className="wr_additionalInfo ">
        <div className="additionalInfoBlock  rounded-3 my-3 mx-auto  d-flex justify-content-between align-items-start flex-column flex-lg-row ">
          <div>
            <h3 className=" additonalInfoHead ">Addtional Information</h3>
            <div className="d-flex  p-2">
              <h6 className="additionalSubHeading   text-dark pt-2">
                Municipal Number
              </h6>
              <p className="ps-3 ">
                <b className="me-md-3 me-2">:</b>
                {propertyDetails.municipalNumber}
              </p>
            </div>
            <div className="d-flex p-2 ">
              <h6 className="additionalSubHeading   text-dark  pt-2">
                Age of Construction
              </h6>
              <p className="ps-3 ">
                <b className="me-md-3 me-2">:</b>
                {propertyDetails.ageOfConstruction}
              </p>
            </div>

            <div className="d-flex p-2">
              <h6 className="additionalSubHeading   text-dark pt-2">
                Floors allowed for Construction
              </h6>
              <p className="ps-3 ">
                <b className="me-md-3 me-2">:</b>
                {propertyDetails.floorsAllowedForConstruction} Floors
              </p>
            </div>

            <div className="d-flex p-2">
              <h6 className="additionalSubHeading   text-dark pt-2">
                Water Facility
              </h6>
              <p className="ps-3 ">
                <b className="me-md-3 me-2">:</b>
                {propertyDetails.waterFacility ? <>Yes</> : <>No</>}
              </p>
            </div>
            <div className="d-flex p-2">
              <h6 className="additionalSubHeading   text-dark pt-2">
                Penthouse
              </h6>
              <p className="ps-3 ">
                <b className="me-md-3 me-2">:</b>
                {propertyDetails.pentHouse ? <>Yes</> : <>No</>}
              </p>
            </div>
            {!clickStatus && (
              <div
                className="text-dark my-4 mx-2 fw-bold viewBtn "
                onClick={handleAllDetailsStatus}
              >
                View All Details <FaChevronDown />
              </div>
            )}
            {clickStatus && (
              <div>
                <div className="d-flex p-2 ">
                  <h6 className="additionalSubHeading    text-dark pt-2 ">
                    Loan Eligibility
                  </h6>
                  <p className="ps-3 ">
                    <b className="me-md-3 me-2">:</b>
                    {propertyDetails.loanEligibility ? <>Yes</> : <>No</>}
                  </p>
                </div>
                <div className="d-flex p-2">
                  <h6 className="additionalSubHeading   text-dark pt-2 ">
                    Website
                  </h6>
                  <p className="ps-3  ">
                    <b className="me-md-3 me-2">:</b> {propertyDetails.website}
                  </p>
                </div>
                <div className="d-flex p-2">
                  <h6 className="additionalSubHeading   text-dark pt-2">
                    Waiting Period
                  </h6>
                  <p className="ps-3 ">
                    <b className="me-md-3 me-2">:</b>
                    {propertyDetails.waitingPeriod}
                  </p>
                </div>
                <div className="d-flex flex-column p-2">
                  <h6 className="abtProperty">About Property</h6>
                  <p className="w-100 fw-bold fw-md-light">
                    {propertyDetails.Description}
                  </p>
                </div>
              </div>
            )}
            {/* <button
              className="border-0 px-4 py-2 rounded-5 bg-danger text-white  "
              onClick={handleContactUsStatus}
            >
              Contact Us
            </button> */}
          </div>
          <div className="abtSeller p-3 rounded-4 mt-4 mt-lg-0">
            <h2>Contact Seller</h2>
            <div className="d-flex align-items-between py-3">
              <h5>{propertyDetails.sellerName}</h5>
              <h5 className="ps-3">+91 924XXXXXXX</h5>
            </div>
            <button
              className="border-0 px-4 py-2 rounded-5 bg-danger text-white"
              onClick={(e) => contactUsPage(e, "contactus")}
            >
              Book a slot
            </button>
          </div>
        </div>
      </div>

      {contactUsStatus && (
        <div className="contactusIndetail d-flex justify-content-center align-items-center flex-column my-4  rounded-3 p-3 w-100">
          <h1>Call Us Now </h1>
          <h5>Comfort is Just a Phone Call Away</h5>
          <span>
            <MdOutlineHorizontalRule />
            <MdOutlineHorizontalRule />
            <MdOutlineHorizontalRule />
            <MdOutlineHorizontalRule />
            <MdOutlineHorizontalRule />
            <MdOutlineHorizontalRule />
            <MdOutlineHorizontalRule />
            <MdOutlineHorizontalRule />
            <MdOutlineHorizontalRule />
            <MdOutlineHorizontalRule />
            <MdOutlineHorizontalRule />
            <MdOutlineHorizontalRule />
          </span>
          <div className="d-flex flex-column flex-md-row justify-content-center">
            <div className="p-3">
              <CiPhone className="iconsDecoration" /> 8688535888
            </div>
            <div className="p-3 ">
              <IoLocationOutline className="iconsDecoration" /> SR Nagar
            </div>
            <div className="p-3">
              <MdOutlineEmail className="iconsDecoration" />
              oneVilla@gmail.com
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PropertyPage;

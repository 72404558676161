import React, { useEffect, useState } from "react";
import { FaIndianRupeeSign } from "react-icons/fa6";
// import Pagination from '@mui/material/Pagination';
import { IoLocationOutline } from "react-icons/io5";
import { BsBuildings } from "react-icons/bs";
import { Link } from "react-router-dom";
import "./buyVilla.css";
import config from "../../boot/config.json";
import axios from "axios";

function VillaCards() {
  const [villaData, setVillaData] = useState([]);

  useEffect(() => {
    (() => {
      try {
        axios
          .get(`${config.baseUrl}/properties/getProperties`)
          .then((response) => {
            setVillaData(response.data.data);
            console.log(response.data.data);
          })
          .catch((err) => {});
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <section className="py-5 w-100 ">
      <h2 className="text-center  mb-4">Let's Buy Your Dream Villa</h2>
      <div className="d-flex justify-content-center align-items-center">
        <div className="d-grid justify-content-center align-items-center villaCardsGrid">
          {villaData.map((villa) => (
            <div
              className="villaCard overflow-hidden mb-4 mx-2 "
              key={villa._id}
            >
              <img
                src={villa.imageUrl}
                alt={`${villa.landmark}`}
                loading="lazy"
              />
              <div className=" p-4">
                <>
                  <div className="d-flex justify-content-between align-items-center ">
                    <h5 className="m-0" title={villa.houseName}>
                      {villa.houseName.slice(0, 14)}...
                    </h5>
                    <div className="d-flex justify-content-center align-items-center areablock rounded-3 p-1 ">
                      <BsBuildings className="text-success me-1" />
                      <p className="m-0">{villa.squareFeet}sqft</p>
                    </div>
                  </div>
                  <p className="locationPin">
                    <IoLocationOutline /> {villa.city} , {villa.state}
                  </p>
                </>
                <p className="desc" title={villa.Description}>
                  {villa.Description.slice(0, 80)}...
                </p>
                <div className="d-flex align-items-center justify-content-between fw-bold">
                  <Link
                    to={`/propertyDetails/${villa.roomsCount}-BHK-${
                      villa.facing
                    }-facing-${villa.squareFeet}-Sq-ft-${
                      villa.pricePerSquareFeet
                    }RS-per-squarefeet-${villa.typeOfProperty
                      .split(" ")
                      .join("-")}-for-sale-${villa.city
                      .split(" ")
                      .join("-")}-in-${villa.state.split(" ").join("-")}-${
                      villa._id
                    }`}
                  >
                    <button className="buyBtn border-0 fw-bold text-white ">
                      Learn more
                    </button>
                  </Link>
                  <div>
                    <FaIndianRupeeSign />
                    {Number(villa.price).toLocaleString("en-IN")}/-
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default VillaCards;

{
  /* <Pagination count={10} color="secondary" onChange={handlePagination} className="d-flex justify-content-center"/> */
}

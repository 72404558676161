import {Routes,Route,useLocation} from 'react-router-dom'
import React,{useEffect} from 'react'
import Sell from '../Sell/Sell'
import LoginForm from '../LoginForm/LoginForm'
import RegistrationForm from '../RegistrationForm/RegistrationForm'
// import HouseDetails from '../HouseDeatils/HouseDetails';
import PropertyPage from '../PropertyPage/PropertyPage'


function NavbarPathConfig() {

  function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }

  
    return (
      <div className="pathConfig">
      <ScrollToTop />
        <Routes>
          <Route path="/sell" element={<Sell />} />
          <Route path='/propertyDetails/:id' element={<PropertyPage />} />
          <Route path='/login' element={<LoginForm/>}/>
          <Route path='/Register' element={<RegistrationForm/>}/>
          {/* <Route path="*" element={<Home />} /> */}
        </Routes>
      </div>
    );
  }
  export default NavbarPathConfig;
  
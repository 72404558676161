import React, { useState, useEffect } from "react";
import "./registrationForm.css";
import axios from "axios";

function RegistrationForm() {
  const fieldset = "d-flex flex-column";
  const [ErrorMessage, setErrorMessage] = useState("");
  const [countries, setCountries] = useState([]);
  const fetchData = async () => {
    await axios
      .get("https://restcountries.com/v3.1/all?fields=name")
      .then((response) => {
        const countryNames = response.data.map(
          (country) => country.name.common
        );
        const sortedCountries = countryNames.sort();
        setCountries(sortedCountries);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    address: "",
    city: "",
    state: "",
    country: "",
    agreeToTerms: false,
    receiveNotifications: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData({ ...formData, [name]: checked });
    } else if (name === "phone") {
      const numericOnly = value.replace(/\D/g, "");
      setFormData({ ...formData, phone: numericOnly });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const {
      name,
      email,
      phone,
      address,
      password,
      confirmPassword,
      city,
      state,
      country,
    } = formData;
    
    const trimmedName = name.trim();
    const trimmedPhone = phone.trim();
    const trimmedEmail = email.trim();
    const trimmedAddress = address.trim();
    const trimmedPassword = password.trim();
    const trimmedConfirmPassword = confirmPassword.trim();
    const trimmedCity = city.trim();
    const trimmedState = state.trim();
    const trimmedCountry = country.trim();

    switch (true) {
      case trimmedName.length === 0:
        setErrorMessage("Please Enter a Valid Name");
        break;
      case trimmedPhone.length === 0:
        setErrorMessage("Enter a valid Mobile Number");
        break;
      case trimmedEmail.includes("@") === false:
        setErrorMessage("Enter a valid Email");
        break;
      case trimmedPassword.length < 8:
        setErrorMessage("Password must contain atleast 8 Characters");
        break;
      case trimmedConfirmPassword !== password.trim():
        setErrorMessage("Passwords Does not match");
        break;
      case trimmedAddress.length < 5:
        setErrorMessage("Enter a Valid Address");
        break;
      case trimmedCity.length === 0:
        setErrorMessage("Enter a valid City");
        break;
      case trimmedState.length === 0:
        setErrorMessage("Enter a Valid State");
        break;
      case trimmedCountry.length === 0:
        setErrorMessage("Select the Country Name");
        break;
      case !formData.agreeToTerms:
        setErrorMessage("Agree to terms and conditions to proceed");
        break;
      default:
        try {
          console.log(formData)
          axios
            .post("url", formData)
            .then((response) => {
              setFormData({
                name: "",
                phone: "",
                email: "",
                password: "",
                confirmPassword: "",
                address: "",
                city: "",
                state: "",
                country: "",
                agreeToTerms: false,
                receiveNotifications: false,
              });
            })
            .catch((err) => {
              console.error("Error in POST request:", err);
            });
        } catch (err) {
          console.error("Error in POST request:", err);
        }
    }
  };

  fetchData();
  return (
    <section className="wr_registrationForm p-1 p-md-3">
      <form
        action="/submit"
        onSubmit={handleSubmit}
        className="Registrationform rounded-3 mx-auto d-grid align-items-center"
      >
        <h1 className="text-center">Registration Form</h1>
        <fieldset className={fieldset}>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            placeholder="Enter Your Name"
            className="inputBox "
            onChange={handleChange}
            value={formData.name}
            name="name"
          />
          <div>
            {ErrorMessage === "Please Enter a Valid Name" ? (
              <p className="text-danger errorClass">{ErrorMessage}</p>
            ) : null}
          </div>
        </fieldset>
        <fieldset className={fieldset}>
          <label htmlFor="phone">Mobile Number</label>
          <input
            type="tel"
            maxLength={10}
            placeholder="Enter Your Mobile Number"
            className="inputBox"
            onChange={handleChange}
            name="phone"
            value={formData.phone}
          />
          <div>
            {ErrorMessage === "Enter a valid Mobile Number" ? (
              <p className="text-danger errorClass">{ErrorMessage}</p>
            ) : null}
          </div>
        </fieldset>

        <fieldset className={fieldset}>
          <label htmlFor="email">Email Address</label>
          <input
            type="text"
            placeholder="Enter Your Email Address"
            className="inputBox"
            onChange={handleChange}
            value={formData.email}
            name="email"
          />
          <div>
            {ErrorMessage === "Enter a valid Email" ? (
              <p className="text-danger errorClass">{ErrorMessage}</p>
            ) : null}
          </div>
        </fieldset>
        <div className="d-md-flex justify-content-between">
          <fieldset className="d-flex flex-column w-100">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              minLength={8}
              className="inputBox  me-md-2"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
            />
            <div>
              {ErrorMessage === "Password must contain atleast 8 Characters" ? (
                <p className="text-danger errorClass">{ErrorMessage}</p>
              ) : null}
            </div>
          </fieldset>

          <fieldset className="d-flex flex-column w-100">
            <label htmlFor="password">Confirm Password</label>
            <input
              type="password"
              placeholder="Confirm Password"
              className="inputBox  ms-md-2"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
            />

            <div>
              {ErrorMessage === "Passwords Does not match" ? (
                <p className="text-danger errorClass">{ErrorMessage}</p>
              ) : null}
            </div>
          </fieldset>
        </div>

        <fieldset className=" Address d-flex flex-column">
          <label htmlFor="address">Address</label>
          <textarea
            placeholder="Enter Your address"
            id="address"
            className="textArea"
            name="address"
            onChange={handleChange}
            value={formData.address}
          />
          <div className="d-md-flex justify-content-between">
            <div className="d-flex flex-column w-100">
              <label htmlFor="city">City</label>
              <input
                type="text"
                placeholder="City"
                id="city"
                className="inputBox me-md-2"
                onChange={handleChange}
                name="city"
                value={formData.city}
              />
            </div>
            <div className="d-flex flex-column w-100">
              <label htmlFor="state">State</label>
              <input
                type="text"
                id="state"
                placeholder="State"
                className="inputBox ms-md-2"
                name="state"
                onChange={handleChange}
                value={formData.state}
              />
            </div>
          </div>

          <label htmlFor="country">Country</label>
          <select
            list="countries"
            name="country"
            id="country"
            className="inputBox"
            placeholder="Select a country"
            onChange={handleChange}
            value={formData.country}
          >
            <option value="" disabled>
              Select Country
            </option>
            {countries.map((country, index) => (
              <option key={index} value={country} className="inputBox">
                {country}
              </option>
            ))}
          </select>
          <div>
            {ErrorMessage === "Enter a Valid Address" ||
            ErrorMessage === "Enter a valid City" ||
            ErrorMessage === "Enter a Valid State" ||
            ErrorMessage === "Select the Country Name" ? (
              <p className="text-danger errorClass">{ErrorMessage}</p>
            ) : null}
          </div>
        </fieldset>

        <div className="d-flex mb-2">
          <input
            type="checkbox"
            id="terms"
            className="me-2"
            name="agreeToTerms"
            onChange={handleChange}
            checked={formData.agreeToTerms}
          />
          <label htmlFor="terms">
            Agree to
            <a href="#terms&conditions" className="text-decoration-none ps-1">  
              terms and Conditions
              <span className="text-danger errorClass">*</span>
            </a>
          </label>
        </div>
        <div className="d-flex mb-2">
          <input
            type="checkbox"
            id="notifications"
            className="me-2"
            name="receiveNotifications"
            onChange={handleChange}
            checked={formData.receiveNotifications}
          />
          <label htmlFor="notifications">
            Agree to recieve notifications on whatsapp, email, Phone
          </label>
        </div>
        <div>
          {ErrorMessage === "Agree to terms and conditions to proceed" ? (
            <p className="text-danger errorClass ">{ErrorMessage} </p>
          ) : null}
        </div>
        <input type="submit" className="btn btn-primary " />
      </form>
    </section>
  );
}

export default RegistrationForm;

import React from "react";
import "./reasonsToChoose.css";
import reasonstochooseus from "../../Assests/reasonstochooseus-min.jpg";

function ReasonsToChoose() {
  return (
    <section className="d-flex justify-content-center flex-column align-items-center mt-3">
      <h1>Promise & Prosper</h1>
      <div className="whyChooseUs d-flex justify-content-center flex-column flex-lg-row">
        <div className="d-flex justify-content-center">
          <img
            src={reasonstochooseus}
            alt="5 reasons to choose us"
            className="reasonstoPartner"
            loading="lazy"
          />
        </div>
        <div className="d-flex align-items-center justify-content-center reasons">
          <div className="reasonsHeading">
            <h3 className="ps-5 reasonsHeading">
              5 Reasons why you will want <br />
              to<b className="text-danger"> partner with us</b>
            </h3>
            <ul type="none" className="mb-5">
              <li>❶ Personalized Investment Strategies:</li>
              <li>❷ Global Reach and Networking:</li>
              <li>❸ Property Tours</li>
              <li>❹ Money-Back Guarantee</li>
              <li>❺ Dedicated Management Experts</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ReasonsToChoose;

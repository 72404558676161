import React, { useState } from "react";
import "./sell.css";
import { MdHorizontalRule } from "react-icons/md";
import axios from "axios";

function Sell() {
  const [isNegotiable, setIsNegotiable] = useState(false);
  const [formData, setFormData] = useState({
    HouseName: "",
    Address: "",
    Pincode: "",
    City: "",
    State: "",
    SquareFeet: "",
    min: "",
    max: "",
    Negotiable: "No",
  });

  const handleSellSubmit = (e) => {
    e.preventDefault();
    try {
      axios
        .post("url")
        .then((res) => {
          setFormData({
            HouseName: "",
            Address: "",
            Pincode: "",
            City: "",
            State: "",
            SquareFeet: "",
            min: "",
            max: "",
            Negotiable: "No",
          });
        })
        .catch((err) => {});
    } catch (err) {
      console.log(err);
    }
    console.log(formData);
  };

  const handleCheckboxChange = () => {
    setIsNegotiable(!isNegotiable);
    if (!isNegotiable) {
      setFormData((prevFormData) => {
        const updatedFormData = { ...prevFormData, Negotiable: "Yes" };
        return updatedFormData;
      });
    }
  };

  const handleInput = (e) => {
    const { id, value } = e.target;

    if (id === "Pincode") {
      const numericOnly = value.replace(/\D/g, ""); // Remove non-numeric characters
      setFormData((prevFormData) => {
        const updatedFormData = { ...prevFormData, [id]: numericOnly };
        return updatedFormData;
      });
    } else if (id === "SquareFeet") {
      const numericOnly = value.replace(/\D/g, ""); // Remove non-numeric characters
      setFormData((prevFormData) => {
        const updatedFormData = { ...prevFormData, [id]: numericOnly };
        return updatedFormData;
      });
    } else {
      setFormData((prevFormData) => {
        const updatedFormData = { ...prevFormData, [id]: value };
        return updatedFormData;
      });
    }
  };

  return (
    <section className="d-flex justify-content-center align-items-center  sellWrapper">
      <div className="d-flex w-100  justify-content-center">
        <div className="w-40 d-grid justify-content-center align-items-center sellFormWrapper">
          <form
            action=""
            className="d-flex flex-column  rounded-4 fw-bold p-3 sellRegisForm "
          >
            <h2>Sell your property at good price</h2>
            <br />
            <div className="d-flex flex-column">
              <label htmlFor="">
                House Name
                <span className="text-danger fs-5">*</span>
              </label>
              <input
                type="text"
                onChange={handleInput}
                id="HouseName"
                name="HouseName"
                value={formData.HouseName}
                placeholder="Eg: Royal Crest Villa"
                required
              />
            </div>
            <div className="d-flex flex-column">
              <label htmlFor="">
                Address <span className="text-danger fs-5">*</span>
              </label>
              <textarea
                id="Address"
                name="Address"
                onChange={handleInput}
                cols="30"
                rows="2"
                value={formData.Address}
                required
              ></textarea>
            </div>
            <div className="d-flex flex-column">
              <label htmlFor="">
                Pincode <span className="text-danger fs-5">*</span>
              </label>
              <input
                type="tel"
                id="Pincode"
                name="Pincode"
                value={formData.Pincode}
                onChange={handleInput}
                maxLength={6}
                placeholder="533101"
                required
              />
            </div>
            <div className="d-flex flex-column">
              <label htmlFor="">
                City <span className="text-danger fs-5">*</span>
              </label>
              <input
                type="text"
                onChange={handleInput}
                id="City"
                name="City"
                value={formData.City}
                placeholder="Hyderabad"
                required
              />
            </div>
            <div className="d-flex flex-column">
              <label htmlFor="">
                State <span className="text-danger fs-5">*</span>
              </label>
              <input
                type="text"
                onChange={handleInput}
                id="State"
                name="State"
                value={formData.State}
                placeholder="Telangana"
                required
              />
            </div>
            <div className="d-flex flex-column">
              <label htmlFor="">
                Square feet <span className="text-danger fs-5">*</span>
              </label>
              <input
                type="tel"
                onChange={handleInput}
                id="SquareFeet"
                name="SquareFeet"
                value={formData.SquareFeet}
                maxLength={10}
                placeholder="1000"
                required
              />
            </div>
            <div className="d-flex flex-column ">
              <label htmlFor="">Price per square feet (RS) </label>
              <div className="d-flex  flex-wrap">
                <div>
                  <div>min:</div>
                  <input
                    type="number"
                    className="me-3"
                    onChange={handleInput}
                    id="min"
                    name="min"
                    value={formData.min}
                  />
                  <MdHorizontalRule />
                </div>
                <div>
                  <div>max:</div>
                  <input
                    type="number"
                    className="ms-md-3"
                    onChange={handleInput}
                    id="max"
                    name="max"
                    value={formData.max}
                  />
                </div>
              </div>
            </div>
            <div>
              <input
                type="checkbox"
                id="isNegotiable"
                className="p-0"
                checked={isNegotiable}
                onChange={handleCheckboxChange}
              />
              <span className="px-2 " onClick={handleCheckboxChange}>
                prices should be negotiable
              </span>
            </div>
            <button
              className="p-2 border-0 bg-secondary text-white fw-bold"
              onClick={handleSellSubmit}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Sell;

import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import villaLogo from "../../Assests/Logo.jpg";
import Burgermenu from "../../Assests/menu.png";
import { Link } from "react-router-dom";
import "./navbar.css";

function Navigation() {
  const [stickyClass, setStickyClass] = useState("");
  const [activeClass, setActiveClass] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    const scrollY = document.documentElement.scrollTop;
    setStickyClass(
      scrollY > 10 ? "sticky-nav position-fixed top-0 start-0 bg-white" : ""
    );
    setActiveClass(scrollY > 100 ? true : false);
  };

  return (
    <section>
      <Navbar
        collapseOnSelect
        expand="lg"
        className={`p-2 d-flex position-relative ${stickyClass}`}
      >
        <Navbar.Brand>
          <img
            src={villaLogo}
            alt="one villa logo"
            className="d-inline-block align-top logo"
            loading="lazy"
          />
        </Navbar.Brand>
        <Navbar.Toggle className="custom-toggler border-0">
          <img src={Burgermenu} alt="menu" loading="lazy" />
        </Navbar.Toggle>
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-end mb-navbarC"
        >
          <Nav className={`navLinks p-4 `}>
            <Nav.Link href="#home" className={activeClass ? "" : "transp"}>
              Home
            </Nav.Link>
            <Nav.Link href="#buy" className={activeClass ? "" : "transp"}>
              Properties
            </Nav.Link>
            <Nav.Link href="#services" className={activeClass ? "" : "transp"}>
              Services
            </Nav.Link>
            <Nav.Link href="#benefits" className={activeClass ? "" : "transp"}>
              Benfits
            </Nav.Link>
            <Nav.Link href="#contact" className={activeClass ? "" : "transp"}>
              Contact
            </Nav.Link>
            {/* <Link to="sell" className="nav-link">
              Sell
            </Link> */}
            {/* <Link to="login" className="nav-link ">
              Login
            </Link>
            <Link to="register" className="nav-link ">
              sign up
            </Link> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </section>
  );
}

export default Navigation;

// import React, { useEffect, useState, useRef } from "react";
// import { Nav, Navbar } from "react-bootstrap";
// import Burgermenu from "../../Assets/burgerMenu.png";
// import "./landingPage.css";
// import LOGO from "../../Assets/svsr-nobg.png";

// const LandingPage = () => {
//   const navRef = useRef();
//   const [show, setShow] = useState(false);

//   const showNavbar = () => {
//     if (navRef.current) {
//       navRef.current.classList.toggle("responsive_nav");
//       setShow(true);
//     }
//   };

//   const [stickyClass, setStickyClass] = useState("");

//   useEffect(() => {
//     window.addEventListener("scroll", stickNavbar);
//     return () => window.removeEventListener("scroll", stickNavbar);
//   }, []);

//   const stickNavbar = () => {
//     const scrollY = document.documentElement.scrollTop;
//     setStickyClass(
//       scrollY > 100 ? "sticky-nav position-fixed top-0 start-0 bg-white" : ""
//     );
//   };

//   const handleClose = () => {
//     setShow(false);
//   };

//   return (
//     <section className="wr_home">
//       <Navbar
//         collapseOnSelect
//         expand="lg"
//         className={`p-2  d-flex position-relative   ${stickyClass}`}
//       >
//         <Navbar.Brand>
//           <img
//             src={LOGO}
//             alt="Company Logo"
//             className="d-inline-block align-top logo"
//           />
//         </Navbar.Brand>
//         <Navbar.Toggle className="custom-toggler border-0" onClick={showNavbar}>
//           <img src={Burgermenu} alt="" />
//         </Navbar.Toggle>
//         <Navbar.Collapse
//           id="basic-navbar-nav"
//           className="justify-content-end mb-navbarC"
//         >
//           <Nav className={`navLinks p-4 ${show ? "responsive_nav" : ""}`}>
//             <Nav.Link href="#home" onSelect={handleClose}>
//               Home
//             </Nav.Link>
//             <Nav.Link href="#about">About Us</Nav.Link>
//             <Nav.Link href="#services">Services</Nav.Link>
//             <Nav.Link href="#benefits">Support</Nav.Link>
//             <Nav.Link href="#contact">Contact Us</Nav.Link>
//           </Nav>
//         </Navbar.Collapse>
//       </Navbar>

//       <div className="headingBlock position-absolute text-center text-md-start">
//         <h1 className="lpHeading">One Stop Solution for Product</h1>
//         <div className="words overflow-hidden text-center text-md-start">
//           <span className="d-block">Development</span>
//           <span className="d-block">Maintenance</span>
//         </div>

//         <p className="lpTagLine text-white">
//           Revolutionizing Businesses through Cutting-Edge IT Solutions and
//           Seamless Development Expertise.
//         </p>
//         <button className="lpButton fw-bold border-0">Hire Developers</button>
//       </div>
//     </section>
//   );
// };

// export default LandingPage;

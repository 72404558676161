import React, { useState } from "react";
import "./contact.css";
import contactus from "../../Assests/contactus1.jpg";
import axios from "axios";
import config from "../../boot/config.json";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    subject: "ONE VILLA CUSTOMER INQUIRY",
  });
  function handleCustomerDetails(e) {
    e.preventDefault();
    try {
      axios
        .post(`${config.baseUrl}/oneVillaContactUs`, formData)
        .then((res) => {
          setFormData({
            name: "",
            email: "",
            phone: "",
            message: "",
          });
          alert("Details sucessfully sent");
        })
        .catch((err) => {});
    } catch (err) {
      console.log(err);
    }
  }

  function handleInputs(e) {
    const { id, value } = e.target;

    if (id === "phone") {
      // If the input is for the phone field, enforce numeric input and apply maxLength
      const numericOnly = value.replace(/\D/g, ""); // Remove non-numeric characters
      const truncatedValue = numericOnly.slice(0, 10); // Apply maxLength constraint

      setFormData((prevFD) => {
        const updatedFD = { ...prevFD, [id]: truncatedValue };
        return updatedFD;
      });
    } else {
      // For other fields, directly update the state
      setFormData((prevFD) => {
        const updatedFD = { ...prevFD, [id]: value };
        return updatedFD;
      });
    }
  }

  return (
    <section className="d-flex  justify-content-center align-items-center w-100 contactWrapper fw-bold contactFormWrapper">
      <div className="d-flex  justify-content-center align-items-center flex-column ">
        <h1>Let's Build Trust with Us</h1>
        <div className="d-flex flex-column flex-lg-row">
          <div className="d-flex flex-column justify-content-center  align-items-center w-md-75 px-md-5">
            <div className="d-flex d-none d-lg-block w-100 align-items-center justify-content-center ">
              <img
                src={contactus}
                className="border-0 contactusImage"
                alt="contact us"
                loading="lazy"
              />
              <div className="position-absolute credit">Image by Freepik</div>
            </div>
          </div>
          <div className="w-100 my-md-5 ">
            <form
              action=""
              className="w-100 d-grid justify-content-center align-items-center py-lg-2 villaFormWrapper "
            >
              <h1 className="fw-bolder">Contact Us</h1>
              <div className="mb-3">
                <label for="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  onChange={handleInputs}
                  value={formData.name}
                  required
                />
              </div>
              <div className="mb-3">
                <label for="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  onChange={handleInputs}
                  value={formData.email}
                  required
                />
              </div>
              <div className="mb-3">
                <label for="phone" className="form-label">
                  Phone
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  name="phone"
                  onChange={handleInputs}
                  value={formData.phone}
                  required
                />
              </div>
              <div className="mb-3">
                <label for="message" className="form-label">
                  Message
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  rows="4"
                  onChange={handleInputs}
                  value={formData.message}
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="btn btn-primary mb-3"
                onClick={handleCustomerDetails}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;

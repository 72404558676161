import React from "react";
import { useLocation } from "react-router-dom";
import LandingPage from "./Components/LandingPage/LandingPage";
import Services from "./Components/Services/services";
import ReasonsToChoose from "./Components/WhyChooseUs/ReasonsToChoose";
import VillaCards from "./Components/BuyVillaCards.js/BuyVilla";
import NavbarPathConfig from "./Components/Navbar/pathConfig";
import Contact from "./Components/ContactUs/Contact";
import Footer from "./Components/Footer/Footer";

function App() {
  const location = useLocation();
  const isSellPage = location.pathname === "/sell";
  const isLogin = location.pathname === "/login";
  const isRegister = location.pathname === "/register";

  const isPropertyDetails = location.pathname.startsWith("/propertyDetails");

  return (
    <div className="App">
      <NavbarPathConfig />

      {!isSellPage && !isPropertyDetails && !isLogin && !isRegister ? (
        <>
          <section id="home">
            <LandingPage />
          </section>
          <section id="buy">
            <VillaCards />
          </section>
          <section id="services">
            <Services />
          </section>
          <section id="benefits">
            <ReasonsToChoose />
          </section>
          <section id="contact">
            <Contact />
          </section>
          <section>
            <Footer />
          </section>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default App;
